import React from "react";
import Card from "./card";
import cardStyles from "./cards.module.scss";
import { useStaticQuery, graphql } from "gatsby";

const Cards = () => {
  const data = useStaticQuery(graphql`
    query {
      hart: file(relativePath: { eq: "images/pis/hart_justin.jpg" }) {
        ...squareImage
      }

      sentis: file(relativePath: { eq: "images/pis/luis-sentis.jpg" }) {
        ...squareImage
      }

      biswas: file(relativePath: { eq: "images/pis/biswas_joydeep.jpg" }) {
        ...squareImage
      }

      jiao: file(relativePath: { eq: "images/pis/junfeng_jiao.jpg" }) {
        ...squareImage
      }
    }
  `);

  return (
    <div className={cardStyles.cards}>
      <a
        className={cardStyles.link}
        href="https://soa.utexas.edu/resources/urban-information-lab"
      >
        <Card data={data} name="Junfeng Jiao" />
      </a>
      <a className={cardStyles.link} href="http://sites.utexas.edu/hcrl/">
        <Card data={data} name="Luis Sentis" />
      </a>
      <a className={cardStyles.link} href="https://amrl.cs.utexas.edu">
        <Card data={data} name="Joydeep Biswas" />
      </a>
      <a className={cardStyles.link} href="https://www.cs.utexas.edu/~larg/index.php/Learning_Agents_Research_Group">
        <Card data={data} name="Justin Hart" />
      </a>
    </div>
  );
};

export default Cards;
