import React from "react";

import Img from "gatsby-image";

import cardStyles from "./card.module.scss";

const Card = ({ data, name }) => {
  const cards = {
    "Justin Hart": {
      fluid: data.hart.childImageSharp.fluid,
      position: "Assistant Professor of Practice",
      department: "Department of Computer Science",
      lab: "#"
    },
    "Luis Sentis": {
      fluid: data.sentis.childImageSharp.fluid,
      position: "Associate Professor",
      department: "Department of Aerospace Engineering",
      lab: "http://sites.utexas.edu/hcrl/"
    },
    "Joydeep Biswas": {
      fluid: data.biswas.childImageSharp.fluid,
      position: "Assistant Professor",
      department: "Department of Computer Science",
      lab: "https://amrl.cs.utexas.edu/people.html"
    },
    "Junfeng Jiao": {
      fluid: data.jiao.childImageSharp.fluid,
      position: "Associate Professor",
      department: "School of Architecture",
      lab: "https://soa.utexas.edu/resources/urban-information-lab"
    },
  };

  return (
    <article className={cardStyles.card}>
      <header>
        <h4>{name}</h4>
        <Img
          className={cardStyles.image}
          fluid={cards[name].fluid}
          alt={name}
        />
        <p className={cardStyles.bio}>
          {cards[name].position} <br /> {cards[name].department} <br /> {name === "Justin Hart" ? <a href="http://justinhart.net">justinhart.net</a> : ""}
        </p>
      </header>
    </article>
  );
};

export default Card;
