import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Head from "../components/head";
import Cards from "../components/cards";

import teamStyles from "./team.module.scss";

const MeetTheTeam = ({ data }) => {
  return (
    <Layout>
      <Head title="Meet the Team" />
      <article className={teamStyles.content}>
        <h2>Principal Investigators</h2>
        <Cards />
        <h2>Research Engineer</h2>
        <div className={teamStyles.row}>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/maxwell-svetlik-591b748b/"
          >
            <div className={teamStyles.container}>
              <Img
                fluid={data.max.childImageSharp.fluid}
                alt="Maxwell Svetlik"
              />
              <div className={teamStyles.info}>
                <div>BS Computer Science, Architecture Team</div>
              </div>
            </div>
            <p>
              Maxwell Svetlik
              <br />
              Architecture Team <br />
              BS Computer Science
            </p>
          </a>
          <div className={teamStyles.column} />
        </div>
        <h2>Students</h2>
        <div className={teamStyles.row}>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img
                fluid={data.anurag.childImageSharp.fluid}
                alt="Anurag Patil"
              />
              <div className={teamStyles.info}>
                <div>
                  MS Computational Science, Engineering and Math, App Team
                </div>
              </div>
            </div>
            <p>
              Anurag Patil <br /> App Team <br /> MS Computational Science{" "}
            </p>
          </a>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/asha-k-jain/"
          >
            <div className={teamStyles.container}>
              <Img fluid={data.asha.childImageSharp.fluid} alt="Asha Jain" />
              <div className={teamStyles.info}>
                <div>BS Aerospace Engineering, App Team</div>
              </div>
            </div>
            <p>
              Asha Jain <br />
              App Team
              <br />
              BS Aerospace Engineering
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/cemkaramanli"
          >
            <div className={teamStyles.container}>
              <Img fluid={data.cem.childImageSharp.fluid} alt="Cem Karamanli" />
              <div className={teamStyles.info}>
                <div>Ph.D ORIE Department, Navigation Team</div>
              </div>
            </div>
            <p>
              Cem Karamanli <br /> Navigation Team <br />
              Ph.D ORIE Department
            </p>
          </a>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/dakshdua/"
          >
            <div className={teamStyles.container}>
              <Img fluid={data.daksh.childImageSharp.fluid} alt="Daksh Dua" />
              <div className={teamStyles.info}>
                <div>BS Computer Science, Architecture Team</div>
              </div>
            </div>
            <p>
              Daksh Dua <br /> Architecture Team <br />
              BS Computer Sceince
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/kent-hansen-6b6203181/"
          >
            <div className={teamStyles.container}>
              <Img fluid={data.kent.childImageSharp.fluid} alt="Kent Hansen" />
              <div className={teamStyles.info}>
                <div>BSA Computer Science, Localization Team</div>
              </div>
            </div>
            <p>
              Kent Hansen <br /> Localization Team <br /> BSA Computer Science
            </p>
          </a>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img
                fluid={data.nick.childImageSharp.fluid}
                alt="Nicholas Machak"
              />
              <div className={teamStyles.info}>
                <div>MS Mechanical Engineering, Navigation Team</div>
              </div>
            </div>
            <p>
              Nicholas Machak <br /> Navigation Team <br /> MS Mechanical
              Engineering
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a className={teamStyles.column} href="https://www.linkedin.com/in/kaiyu-zhou1996/">
            <div className={teamStyles.container}>
              <Img fluid={data.minkyu.childImageSharp.fluid} alt="Minkyu Kim" />
              <div className={teamStyles.info}>
                <div>Ph.D Mechanical Engineering, Navigation Team</div>
              </div>
            </div>
            <p>
              Minkyu Kim <br /> Navigation Team <br /> Ph.D Mechanical
              Engineering
            </p>
          </a>
          <a className={teamStyles.column} href="https://www.linkedin.com/in/kaiyu-zhou1996/">
            <div className={teamStyles.container}>
              <Img fluid={data.kaiyu.childImageSharp.fluid} alt="Kaiyu Zhou" />{" "}
              <div className={teamStyles.info}>
                <div>MS Urban Design, App Team</div>
              </div>
            </div>
            <p>
              Kaiyu Zhou
              <br />
              App Team
              <br />
              MS Urban Design
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img
                fluid={data.marika.childImageSharp.fluid}
                alt="Marika Murphy"
              />
              <div className={teamStyles.info}>
                <div>BS Computer Science, Architecture Team</div>
              </div>
            </div>
            <p>
              Marika Murphy
              <br />
              Architecture Team
              <br />
              BS Computer Science
            </p>
          </a>
          <a
            className={teamStyles.column}
            href="https://www.linkedin.com/in/shikhar-g/"
          >
            <div className={teamStyles.container}>
              <Img
                fluid={data.shikhar.childImageSharp.fluid}
                alt="Shikhar Gupta"
              />
              <div className={teamStyles.info}>
                <div>BS Computer Science, Architecture Team</div>
              </div>
            </div>
            <p>
              Shikhar Gupta
              <br />
              Architecture Team
              <br />
              BS Computer Science
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img
                fluid={data.william.childImageSharp.fluid}
                alt="William Kwon"
              />
              <div className={teamStyles.info}>
                <div>BS Applied Math and CS, App Team</div>
              </div>
            </div>
            <p>
              William Kwon
              <br />
              App Team
              <br />
              BS Applied Mathematics and Computer Science
            </p>
          </a>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img fluid={data.ryan.childImageSharp.fluid} alt="Ryan Gupta" />
              <div className={teamStyles.info}>
                <div>Ph.D Aerospace Engineering, Navigation Team</div>
              </div>
            </div>
            <p>
              Ryan Gupta
              <br />
              Navigation Team
              <br />
              Ph.D Aerospace Engineering
            </p>
          </a>
        </div>
        <div className={teamStyles.row}>
          <a className={teamStyles.column}>
            <div className={teamStyles.container}>
              <Img
                fluid={data.kavan.childImageSharp.fluid}
                alt="Kavan Sikand"
              />
              <div className={teamStyles.info}>
                <div>Ph.D Computer Science, Localization Team</div>
              </div>
            </div>
            <p>
              Kavan Sikand
              <br />
              Localization Team
              <br />
              Ph.D Computer Science
            </p>
          </a>
          <div className={teamStyles.column} />
        </div>
      </article>
    </Layout>
  );
};

export const image = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    avatar: file(relativePath: { eq: "avatar.png" }) {
      ...squareImage
    }

    asha: file(relativePath: { eq: "images/students/asha.jpg" }) {
      ...squareImage
    }

    cem: file(relativePath: { eq: "images/students/cem.jpg" }) {
      ...squareImage
    }

    kaiyu: file(relativePath: { eq: "images/students/kaiyu.jpg" }) {
      ...squareImage
    }

    kent: file(relativePath: { eq: "images/students/kent.jpeg" }) {
      ...squareImage
    }

    max: file(relativePath: { eq: "images/students/max.jpeg" }) {
      ...squareImage
    }

    nick: file(relativePath: { eq: "images/students/nick.JPG" }) {
      ...squareImage
    }

    ryan: file(relativePath: { eq: "images/students/ryan.png" }) {
      ...squareImage
    }

    shikhar: file(relativePath: { eq: "images/students/shikhar.jpg" }) {
      ...squareImage
    }

    william: file(relativePath: { eq: "images/students/william.png" }) {
      ...squareImage
    }

    anurag: file(relativePath: { eq: "images/students/anurag.jpg" }) {
      ...squareImage
    }

    daksh: file(relativePath: { eq: "images/students/daksh.JPG" }) {
      ...squareImage
    }

    marika: file(relativePath: { eq: "images/students/marika.JPG" }) {
      ...squareImage
    }

    minkyu: file(relativePath: { eq: "images/students/minkyu.jpg" }) {
      ...squareImage
    }

    kavan: file(relativePath: { eq: "images/students/kavan.png" }) {
      ...squareImage
    }
  }
`;

export default MeetTheTeam;
